import { React, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import YouTube from "react-youtube";
const codeString = `<?php 
function mysterieusGetal($x) {
  $y = geheimeBerekening($x);
  $z = verborgenOperatie($y);
  return $z * 29.125;
}

function geheimeBerekening($a) {
  if ($a <= 1) {
    return 1; 
  }
  return geheimeBerekening($a - 1) + geheimeBerekening($a - 2);
}

function verborgenOperatie($b) {
  $c = $b * 4; 
  $d = specialeFormule($c);
  return $d;
}

function specialeFormule($e) {
  $f = $e * 2;
  return $f;
}

$resultaat = mysterieusGetal(5);
echo "Het mysterieuze getal is: " . $resultaat;
?>`;

const Bob = () => {
  const [videoFinished, setVideoFinished] = useState(false);

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };
  return (
    <div>
      {!videoFinished && (
        <YouTube
          videoId="bkfFGq9uOpo"
          opts={opts}
          onEnd={() => setVideoFinished(true)}
          className="fixed w-screen h-screen top-0 left-0 z[-1]"
        />
      )}
      {videoFinished && (
        <SyntaxHighlighter language="php">{codeString}</SyntaxHighlighter>
      )}
    </div>
  );
};

export default Bob;
