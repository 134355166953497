import React from "react";
import woordzoeker from "./imgs/Woordzoeker.jpeg";
import YouTube from "react-youtube";
import { useState } from "react";

const Erik = () => {
  const [videoFinished, setVideoFinished] = useState(false);

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };
  return (
    <div>
      {!videoFinished && (
        <YouTube
          videoId="RA2Bu5W3X2w"
          opts={opts}
          onEnd={() => setVideoFinished(true)}
          className="fixed w-screen h-screen top-0 left-0 z[-1]"
        />
      )}
      {videoFinished && (
        <img className="woordzoeker" src={woordzoeker} alt="" />
      )}
    </div>
  );
};

export default Erik;
