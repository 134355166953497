import React, { useState } from "react";

function ToggleButton({ onClick }) {
  const [isOn, setIsOn] = useState(false);

  const toggleSwitch = () => setIsOn(!isOn);

  return (
    <button
      onClick={() => {
        toggleSwitch();
        onClick();
      }}
      type="button"
      className={`fixed bottom-2 right-2 inline-flex items-center h-6 w-11 rounded-full z-[0] ${
        !isOn ? "bg-blue-600" : "bg-gray-200"
      }`}
    >
      <span
        className={`${
          !isOn ? "translate-x-6" : "translate-x-1"
        } inline-block w-4 h-4 transform bg-black rounded-full`}
      />
    </button>
  );
}

export default ToggleButton;
