import React, { useRef, useEffect } from "react";

const Snowfall = ({
  snowflakeCount = 300,
  dk = false,
  color1 = "#460182",
  color2 = "#ffd200",
  color = "white",
  wind = true,
  radius = 4,
  speed = 1,
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const snowflakes = [];

    for (let i = 0; i < snowflakeCount; i++) {
      snowflakes.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * radius + 1,
        windSpeed: Math.random() * (wind ? 0.5 : 0),
        speed: Math.random() * speed + 0.5,
        index: i, // Assign index here
      });
    }

    function drawSnowflake(snowflake) {
      ctx.beginPath();
      ctx.arc(snowflake.x, snowflake.y, snowflake.radius, 0, Math.PI * 2);

      if (dk) {
        ctx.fillStyle = snowflake.index < snowflakeCount / 2 ? color1 : color2;
      } else {
        ctx.fillStyle = color;
      }
      ctx.fill();
    }

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      snowflakes.forEach((snowflake) => {
        snowflake.y += snowflake.speed;
        snowflake.x += snowflake.windSpeed; // Apply wind effect

        if (snowflake.y > canvas.height) {
          snowflake.y = 0;
          snowflake.x = Math.random() * canvas.width;
        }

        drawSnowflake(snowflake);
      });
    }

    animate();

    window.addEventListener("resize", () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    });

    return () => {
      window.removeEventListener("resize", () => {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
      });
    };
  }, [snowflakeCount, color, color1, color2, wind, radius, speed, dk]);

  return (
    <canvas
      ref={canvasRef}
      className="z-[10]"
      style={{ position: "fixed", top: 0, left: 0, pointerEvents: "none" }}
    />
  );
};

export default Snowfall;
