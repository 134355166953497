import { React, useState } from "react";
import bgimage from "./imgs/bg.jpeg";
import Bob from "./Bob";
import Erik from "./Erik";
import YouTube from "react-youtube";
import Snowfall from "./Snowfall";
import ToggleButton from "./components/ToggleButton";
import FunnyButton from "./components/FunnyButton";

// function reverseName(name) {
//   // Split the string into an array of characters
//   const nameArray = name.split("");

//   // Reverse the array of characters
//   const reversedNameArray = nameArray.reverse();

//   // Join the array of characters back into a string
//   const reversedName = reversedNameArray.join("");

//   // Return the reversed name
//   return reversedName;
// }

// export const snowColors = ["#ffffff", "DK"];

function App() {
  const [gebruiker, setGebruiker] = useState("");
  const [status, setStatus] = useState("inloggen");
  const [foutmelding, setFoutmelding] = useState("");
  const [videoFinished, setVideoFinished] = useState(false); // State to track video completion
  const [sneeuw, setSneeuw] = useState(true);
  const [sneeuwColor] = useState("#ffffff");
  const [dk, setDK] = useState(false);

  console.log(videoFinished);

  console.log(process.env.ERIK_CODE);
  console.log(process.env.BOB_CODE);
  const namen = "ENGBGMTCRMRTGJDSN";
  const gebroorteData =
    "19801981198219831983198719911992199319931995199919992000200120042008";

  const handleLogin = () => {
    if (gebruiker === gebroorteData) {
      setStatus("erik");
    } else if (gebruiker === namen) {
      setStatus("bob");
    } else {
      setFoutmelding("Jij bent niet bob of erik. WAT DOE JE HIER?");
      setStatus("inloggen");
    }
  };

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div className="bg-cover bg-center min-h-screen flex flex-col items-center justify-center overflow-hidden">
      {!videoFinished && (
        <YouTube
          videoId="3fIuq2dF1Is"
          opts={opts}
          onEnd={() => setVideoFinished(true)}
          className="absolute w-screen h-screen object-cover z-[5]"
        />
      )}
      {sneeuw && <Snowfall color={sneeuwColor} dk={dk} />}
      {videoFinished && (
        <>
          {" "}
          {/* Added a container div */}
          <img
            src={bgimage}
            alt="background"
            className="absolute w-full h-full object-cover z-[-1]"
          />
          <h1
            className={
              `text-8xl font-bold text-shadow-lg ` +
              (!dk ? `text-red-500 ` : `text-[#ffd200]`)
            }
          >
            Mrs-
            <button
              onClick={() => setDK(!dk)}
              className={!dk ? `` : `text-[#460182]`}
            >
              X
            </button>
          </h1>
          {
            // Switch statement binnen een expressie blok
            (() => {
              switch (status) {
                case "inloggen":
                  return (
                    <>
                      <input
                        type="text"
                        placeholder="Geheim wachtwoord"
                        className="text-2xl mt-8 text-shadow font-bold p-2 rounded-md"
                        onChange={(e) => {
                          setGebruiker(e.target.value);
                          setFoutmelding("");
                        }}
                      />

                      <div className="mt-1 mb-4">
                        <p
                          className={`text-red-500 fixed left-1/2 transform -translate-x-1/2  ${
                            foutmelding ? "" : "hidden"
                          }`}
                        >
                          {foutmelding}
                        </p>
                      </div>
                      {!dk && (
                        <button
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4 "
                          onClick={handleLogin}
                        >
                          Login
                        </button>
                      )}
                      {dk && <FunnyButton onClick={handleLogin} />}
                    </>
                  );
                case "bob":
                  return <Bob setSnowfall={setSneeuw} />;
                case "erik":
                  return <Erik />;
                default:
                  return <p>Onbekende status: {status}</p>; // Of toon een foutmelding
              }
            })()
          }
        </>
      )}
      <ToggleButton onClick={() => setSneeuw(!sneeuw)} />
    </div>
  );
}

export default App;
