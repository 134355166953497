import React, { useState, useEffect, useRef, useCallback } from "react";

function FunnyButton({ onClick }) {
  const [position, setPosition] = useState({ top: 1, left: 0 });
  const [tries, setTries] = useState(0);
  const [size, setSize] = useState({ width: 100, height: 40 });
  const [buttonStyle, setButtonStyle] = useState({
    position: "relative",
    top: `${position.top}rem`,
    left: `${position.left}rem`,
    transition: "all 0.2s ease-in-out",
    width: `${size.width}px`,
    height: `${size.height}px`,
  });

  const buttonRef = useRef(null);

  const handleClick = useCallback(() => {
    if (tries === 4) {
      onClick();
      setTries(0);
      return;
    }

    if (Math.random() < 0.5) {
      const newWidth = Math.floor(Math.random() * 100) + 50;
      const newHeight = Math.floor(Math.random() * 50) + 25;
      setSize({ width: newWidth, height: newHeight });
    }

    const directions = ["left", "right", "up", "down"];
    const randomDirection = directions[tries];

    let newPosition = { ...position };
    switch (randomDirection) {
      case "left":
        newPosition.left -= Math.floor(Math.random() * 10) + 1;
        newPosition.top -= Math.floor(Math.random() * 10) + 1;
        break;
      case "right":
        newPosition.left += Math.floor(Math.random() * 10) + 1;
        newPosition.top += Math.floor(Math.random() * 15) + 1;
        break;
      case "up":
        newPosition.top += Math.floor(Math.random() * 10) + 1;
        break;
      case "down":
        newPosition.left += Math.floor(Math.random() * 5) + 1;
        newPosition.top += Math.floor(Math.random() * 10) + 1;
        break;
      default:
        break;
    }

    const screenWidth = window.innerWidth;
    // const screenHeight = window.innerHeight;

    newPosition.left = Math.max(
      0,
      Math.min(newPosition.left, screenWidth - size.width)
    );
    newPosition.top = Math.max(
      0,
      Math.min(newPosition.top, window.innerHeight - size.height)
    );

    const randomRotation = Math.floor(Math.random() * 360);

    setButtonStyle((prevStyle) => ({
      ...prevStyle,
      top: `${newPosition.top}rem`,
      left: `${newPosition.left}rem`,
      width: `${size.width}px`,
      height: `${size.height}px`,
      transform: `rotate(${randomRotation}deg)`,
    }));

    setPosition(newPosition);
    setTries(tries + 1);
  }, [onClick, position, size, tries]);

  useEffect(() => {
    if (buttonRef.current) {
      const button = buttonRef.current;
      button.style.position = buttonStyle.position;
      button.style.top = buttonStyle.top;
      button.style.left = buttonStyle.left;
      button.style.width = buttonStyle.width;
      button.style.height = buttonStyle.height;
      button.style.transition = buttonStyle.transition;
      button.style.transform = buttonStyle.transform;
    }
    const interval = setInterval(() => {
      handleClick();
    }, Math.floor(Math.random() * 2000) + 500);
    return () => clearInterval(interval);
  }, [handleClick, buttonStyle]); // <--- Empty dependency array

  return (
    <div>
      <button
        ref={buttonRef}
        className="bg-red-500 hover:bg-red-700 text-white font-bold px-4 rounded"
        onClick={() => {
          onClick();
          handleClick();
        }}
      >
        Login
      </button>
    </div>
  );
}

export default FunnyButton;
